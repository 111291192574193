import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Hero from 'components/hero'
import Layout from 'components/layout'
import TextContainer from 'components/common/text-container'
import { Heading } from 'components/common/typography'

const EventsPage = ({ data }) => (
  <Layout>
    <Helmet
      title='Events | Pinya Letters'
      meta={[
        {
          name: 'description',
          content:
            'Pinya Letters hosts and makes appearances regularly at various events, including calligraphy workshops, culture festivals, live store demos, and more!',
        },
      ]}
    />
    <Hero file={data.heroImage} />
    <TextContainer>
      <Heading>Events</Heading>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras fringilla ullamcorper magna,
        quis varius odio molestie a.
      </p>
    </TextContainer>
  </Layout>
)

export const query = graphql`
  query EventsPageQuery {
    heroImage: file(name: { eq: "hero6" }) {
      ...HeroFile
    }
  }
`

export default EventsPage
